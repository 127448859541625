import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import { createStore, applyMiddleware, combineReducers, compose } from 'redux'
import { Provider } from 'react-redux'
import createMiddlewareSaga from 'redux-saga'

import * as serviceWorker from './serviceWorker'
import * as reducers from './store/reducers'
import rootSaga from './store/sagas'

const sagaMiddleware = createMiddlewareSaga()
const reduxDevTools = window.__REDUX_DEVTOOLS_EXTENSION__
    ? window.__REDUX_DEVTOOLS_EXTENSION__()
    : (f) => f

export const store = createStore(
    combineReducers(reducers),
    compose(applyMiddleware(sagaMiddleware), reduxDevTools)
)

sagaMiddleware.run(rootSaga)

ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>,
    document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
