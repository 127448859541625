import Immutable from 'seamless-immutable'
import * as types from './actionTypes'

const initialState = Immutable({
    email: null,
    currentProject: null,
    constants: null,
})

export default function reduce(state = initialState, action = {}) {
    switch (action.type) {
        case types.MAIN_EMAIL_LOAD_END:
            return state.merge({
                email: action.email,
            })

        case types.MAIN_SET_CURRENT_PROJECT:
            return state.merge({
                currentProject: action.project,
            })
        case types.MAIN_LOAD_CONSTANTS_LIST_END:
            return state.merge({
                constants: action.payload,
            })
        case types.EDIT_CONTENT_END:
            return state.merge({
                constants: action.payload,
            })
        default:
            return state
    }
}
export function getConstants(state) {
    return state.main.constants
}

export function getEmail(state) {
    return state.main.email
}

export function getCurrentProject(state) {
    return state.main.currentProject
}
