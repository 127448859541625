import React, { Component } from 'react'
import { HashRouter, Route, Switch } from 'react-router-dom'
import '@fortawesome/fontawesome-free/css/all.min.css'
import 'bootstrap-css-only/css/bootstrap.min.css'
import 'mdbreact/dist/css/mdb.css'
import 'react-toastify/dist/ReactToastify.min.css'
import './App.css'
import Main from './containers/Main'

class App extends Component {
    render() {
        return (
            <HashRouter>
                <Switch>
                    <Route path="/:projectName/:pageName" component={Main} />
                    <Route exact path="/" component={Main} />
                </Switch>
            </HashRouter>
        )
    }
}

export default App
