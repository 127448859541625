import React from 'react'
import { Card } from '@material-ui/core'
import moment from 'moment'
import classNames from 'classnames'
import { connect } from 'react-redux'

import classes from './TicketsList.module.scss'
import {
    getTicketById,
    getTicketsByPlayer,
} from '../../store/InGameTickets/reducer'
import * as types from '../../store/InGameTickets/actionTypes'

const TicketsList = ({ tickets, loadTicket, className, currentTicketId }) => {
    const isSite = () => {
        return window.location.hash.includes('site-tickets')
    }
    return (
        <ul className={classNames(classes.TicketsList, className)}>
            {tickets && (
                <>
                    {tickets.length &&
                        tickets.map((ticket) => (
                            <li
                                key={ticket.id}
                                onClick={() => loadTicket(ticket.id, isSite())}
                            >
                                <Card
                                    className={classNames(
                                        classes.TicketsList__card,
                                        currentTicketId === ticket.id &&
                                            classes.TicketsList__card_active,
                                        ticket.state === 'waiting' &&
                                            classes.waiting,
                                        ticket.state === 'closed' &&
                                            classes.close,
                                        ticket.state === 'waiting_for_player' &&
                                            classes.waiting_for_player
                                    )}
                                >
                                    <span>{ticket.title}</span>
                                    <span>
                                        <b>ID:</b> {ticket.id}
                                    </span>
                                    <span>
                                        <b>Created:</b>{' '}
                                        {moment(ticket.created_on).format(
                                            'DD.MM.YYYY HH:mm'
                                        )}
                                    </span>
                                    <span>
                                        <b>State:</b>
                                        {ticket.state}
                                    </span>
                                </Card>
                            </li>
                        ))}
                </>
            )}
        </ul>
    )
}

const mapStateToProps = (state) => ({
    currentTicketId: getTicketById(state).id,
    tickets: getTicketsByPlayer(state),
})

const mapDispatchToProps = (dispatch) => ({
    loadTicket: (id, site) => dispatch({ type: types.GET_TICKET_LOAD_START, id, site }),
})

export default connect(mapStateToProps, mapDispatchToProps)(TicketsList)
