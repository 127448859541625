import React, { useEffect } from 'react'
import QuickAnswersTable from '../../components/QuickAnswersTable/QuickAnswersTable'
import * as types from '../../store/QuickAnswers/actionTypes'
import {
    getAnswers,
    getAnswersIsLoading,
    getDeletedAnswer,
} from '../../store/QuickAnswers/reducer'
import { connect } from 'react-redux'

const QuickAnswers = ({
    loadListAnswers,
    answers,
    answersIsLoading,
    addNewAnswer,
    deleteAnswer,
    deletedId,
    editAnswer,
}) => {
    useEffect(() => {
        loadListAnswers()
    }, [])
    return (
        <QuickAnswersTable
            answers={answers}
            loading={answersIsLoading}
            addNewAnswer={addNewAnswer}
            deleteAnswer={deleteAnswer}
            deletedId={deletedId}
            editAnswer={editAnswer}
        />
    )
}

const mapStateToProps = (state) => ({
    answers: getAnswers(state),
    answersIsLoading: getAnswersIsLoading(state),
    deletedId: getDeletedAnswer(state),
})

const mapDispatchToProps = (dispatch) => ({
    loadListAnswers: () =>
        dispatch({ type: types.LOAD_LIST_QUICK_ANSWERS_START }),
    addNewAnswer: (title, text) =>
        dispatch({ type: types.SET_QUICK_ANSWER_START, title, text }),
    deleteAnswer: (id, answers) =>
        dispatch({ type: types.DELETE_QUICK_ANSWER_START, id, answers }),
    editAnswer: (active, title, text, id, answers) =>
        dispatch({
            type: types.EDIT_QUICK_ANSWER_START,
            active,
            title,
            text,
            id,
            answers,
        }),
})

export default connect(mapStateToProps, mapDispatchToProps)(QuickAnswers)
