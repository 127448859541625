import React from 'react'
import { Dialog, DialogTitle, DialogContent } from '@material-ui/core'
import TicketsList from '../TicketsList/TicketsList'
import PlayerInfo from '../PlayerInfo/PlayerInfo'
import TicketInfo from '../TicketInfo/TicketInfo'
import { getTicketById } from '../../store/InGameTickets/reducer'
import { connect } from 'react-redux'

const DialogTicket = ({ onClose, open, ticket }) => {
    return (
        <Dialog
            fullWidth={true}
            maxWidth={'xl'}
            onClose={onClose}
            aria-labelledby="customized-dialog-title"
            open={open}
        >
            {ticket && (
                <div style={{ background: '#f0f3f5' }}>
                    <DialogTitle>
                        {ticket && (
                            <PlayerInfo
                                profile_id={ticket.profile_id}
                                player_info={ticket.player_info}
                            />
                        )}
                    </DialogTitle>
                    <DialogContent>
                        <div style={{ display: 'flex' }}>
                            <TicketInfo />
                            <TicketsList />
                        </div>
                    </DialogContent>
                </div>
            )}
        </Dialog>
    )
}

const mapStateToProps = (state) => ({
    ticket: getTicketById(state),
})

export default connect(mapStateToProps, null)(DialogTicket)
