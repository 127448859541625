export const LOAD_LIST_QUICK_ANSWERS_START = 'load list quick answers start'
export const LOAD_LIST_QUICK_ANSWERS_END = 'load list quick answers end'

export const SET_QUICK_ANSWER_START = 'set quick answer start'
export const SET_QUICK_ANSWER_END = 'set quick answer end'

export const DELETE_QUICK_ANSWER_START = 'delete quick answer start'
export const EDIT_QUICK_ANSWER_END = 'edit quick answer end'

export const EDIT_QUICK_ANSWER_START = 'edit quick answer start'
// export const EDIT_QUICK_ANSWER_END = 'edit quick answer end'
