import React, { useState } from 'react'
import {
    MDBCard,
    MDBCardHeader,
    MDBCol,
    MDBRow,
    MDBTable,
    MDBTableBody,
    MDBTableHead,
} from 'mdbreact'
import {
    Button,
    CircularProgress,
    TextField,
    TableRow,
    TableCell,
} from '@material-ui/core'
import AnswerFiled from '../AnswerFiled/AnswerFiled'

const QuickAnswersTable = ({
    loading,
    answers,
    addNewAnswer,
    deleteAnswer,
    editAnswer,
}) => {
    const [title, setTitle] = useState('')
    const [text, setText] = useState('')
    return (
        <MDBCard>
            <MDBCardHeader>
                <MDBRow>
                    <MDBCol>
                        <h2>Quick answers</h2>
                    </MDBCol>
                </MDBRow>
            </MDBCardHeader>
            <MDBTable>
                <MDBTableHead>
                    <tr>
                        <th>Title</th>
                        <th>Text</th>
                        <th>Active</th>
                        <th style={{ textAlign: 'center' }}>Last updated</th>
                    </tr>
                </MDBTableHead>
                <MDBTableBody>
                    <tr>
                        <td>
                            <TextField
                                variant="outlined"
                                onChange={(event) => {
                                    setTitle(event.target.value)
                                }}
                                value={title}
                                className="w-100"
                                size={'small'}
                            />
                        </td>
                        <td>
                            <TextField
                                onChange={(event) => {
                                    setText(event.target.value)
                                }}
                                value={text}
                                multiline
                                rows={1}
                                variant="outlined"
                                className="w-100"
                                size={'small'}
                            />
                        </td>
                        <td />
                        <td />
                        <td>
                            <Button
                                disabled={!text.length || !title.length}
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    addNewAnswer(title, text)
                                }}
                                style={{
                                    height: '25px',
                                    marginTop: '20px',
                                    padding: '1px',
                                }}
                            >
                                Add
                            </Button>
                        </td>
                    </tr>
                    {loading ? (
                        <TableRow>
                            <TableCell colSpan={5}>
                                <div className="text-center">
                                    <CircularProgress
                                        variant="indeterminate"
                                        size={50}
                                        thickness={5}
                                    />
                                </div>
                            </TableCell>
                        </TableRow>
                    ) : (
                        <>
                            {answers && answers.length ? (
                                <>
                                    {answers.map((answer) => (
                                        <AnswerFiled
                                            key={answer.id}
                                            {...answer}
                                            deleteAnswer={() =>
                                                deleteAnswer(answer.id, answers)
                                            }
                                            editAnswer={editAnswer}
                                            answers={answers}
                                        />
                                    ))}
                                </>
                            ) : (
                                <tr>
                                    <td colSpan={5} className="text-center">
                                        No data
                                    </td>
                                </tr>
                            )}
                        </>
                    )}
                </MDBTableBody>
            </MDBTable>
        </MDBCard>
    )
}

export default QuickAnswersTable
