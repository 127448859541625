export const GAME_TICKETS_LIST_LOAD_START = 'game tickets list start'
export const GAME_TICKETS_LIST_LOAD_END = 'game tickets list end'

export const GET_TICKET_LOAD_START = 'get ticket load start'
export const GET_TICKET_LOAD_END = 'get ticket load end'

export const GET_TICKETS_BY_PLAYER_LOAD_START =
    'get tickets by player load start'
export const GET_TICKETS_BY_PLAYER_LOAD_END = 'get tickets by player load end'

export const SET_TICKET_STATE_START = 'set tickets status start'
export const SET_TICKET_STATE_END = 'set tickets status end'

export const SEND_FROM_SUPPORT_START = 'send from support start'
export const SEND_FROM_SUPPORT_END = 'send from support end'

export const SEND_COMMENT_START = 'send comment start'
export const SEND_COMMENT_END = 'send comment end'

export const GAME_LOAD_MORE_START = 'game load more start'
export const GAME_LOAD_MORE_END = 'game load more end'
