import React from 'react'
import classNames from 'classnames'
import classes from './PlayerInfo.module.scss'

const PlayerInfo = ({ profile_id, player_info, className }) => {
    return (
        <div className={classNames(classes.PlayerInfo, className)}>
            <div className={classes.PlayerInfo__profile}>
                <i
                    className={classNames(
                        classes.PlayerInfo__ico,
                        'fas fa-user-alt fa-3x'
                    )}
                />
                <div>
                    <span>
                        <b>ID</b>: {profile_id + ' '}
                        <b>redeem code</b>: {player_info && player_info.redeem}
                    </span>
                    <br />
                    <span>
                        <b>Name</b>: {player_info && player_info.name}
                    </span>
                </div>
            </div>
        </div>
    )
}

export default PlayerInfo
