import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'

import InGameTickets from '../../components/TicketsTable/InGameTickets'
import {
    getNextCursor,
    getTickets,
    getTicketsIsLoading,
} from '../../store/InGameTickets/reducer'
import * as types from '../../store/InGameTickets/actionTypes'
import DialogTicket from '../../components/DialogTicket/DialogTicket'
import classes from './InGameTickets.module.scss'

const getQuestFilters = (filters) => {
    const questFilter = {}
    Object.keys(filters).forEach((key) => {
        if (filters[key].length) {
            switch (key) {
                case 'ticket_id': {
                    questFilter['ticket_id ='] = filters[key]
                    break
                }
                case 'state': {
                    if (filters[key] !== 'all') {
                        questFilter['state ='] = filters[key]
                    }
                    break
                }
                case 'message_text': {
                    questFilter['message_text ~'] = filters[key]
                    break
                }
                case 'message_contact_info': {
                    questFilter['message_contact_info ~'] = filters[key]
                    break
                }
                case 'message_ip': {
                    questFilter['message_ip ~'] = filters[key]
                    break
                }
                case 'paying': {
                    questFilter['paying ='] = filters[key]
                    break
                }
                case 'message_client_info_platform': {
                    questFilter['message_client_info_platform ~'] = filters[key]
                    break
                }
                case 'profile_id': {
                    questFilter['profile_id ='] = filters[key]
                    break
                }
                default:
                    break
            }
        }
    })
    return questFilter
}

const InGameTicketsContainer = ({
    tickets,
    ticketsIsLoading,
    loadListTickets,
    loadTicket,
    loadTicketsByPlayer,
    loadMore,
    cursor,
}) => {
    const [filters, setFilters] = useState({
        ticket_id: '',
        sort_by: 'first_unanswered_on',
        state: 'opened',
        message_text: '',
        message_contact_info: '',
        message_ip: '',
        paying: 'all',
        message_client_info_platform: '',
        profile_id: '',
    })
    const [openTicket, setOpenTicket] = useState(false)
    const [rows, setDataRows] = useState([])

    useEffect(() => {
        applyHandler()
    }, [])

    useEffect(() => {
        const rows = []
        if (tickets && tickets.length) {
            tickets.forEach((ticket) => {
                rows.push({
                    profile_id: ticket.profile_id,
                    id: ticket.id,
                    created: ticket.created_on && (
                        <>
                            <b>{moment(ticket.created_on).format('HH:mm')}</b>
                            <br />
                            <span className={classes.textSmall}>
                                {moment(ticket.created_on).format('DD.MM.YYYY')}
                            </span>
                        </>
                    ),
                    sines: ticket.first_unanswered_on && (
                        <>
                            <b>
                                {moment(ticket.first_unanswered_on).format(
                                    'HH:mm'
                                )}
                            </b>
                            <br />
                            <span className={classes.textSmall}>
                                {moment(ticket.first_unanswered_on).format(
                                    'DD.MM.YYYY'
                                )}
                            </span>
                        </>
                    ),
                    state: ticket.state,
                    lastPlayerMessage: (
                        <>
                            <span className={classes.textSmall}>
                                <div>
                                    {moment(
                                        ticket.last_player_message.sent_on
                                    ).format('DD.MM.YYYY HH:mm')}
                                </div>
                            </span>
                            <div style={{ maxWidth: '350px' }}>
                                {ticket.last_player_message.text.length > 103
                                    ? ticket.last_player_message.text.slice(
                                          0,
                                          100
                                      ) + '...'
                                    : ticket.last_player_message.text}
                            </div>
                        </>
                    ),
                    contact: ticket.last_player_message.contact_info,
                    ip: ticket.last_player_message.ip,
                    payments: ticket.player_info &&
                        ticket.player_info.payments_bit && (
                            <div>
                                {ticket.player_info.payments_bit.payments_count}
                                <br />
                                <b>
                                    $
                                    {`{${ticket.player_info.payments_bit.total_payment
                                        .toFixed(2)
                                        .replace(/\d(?=(\d{3})+\.)/g, '$&,')}}`}
                                </b>
                                <br />
                                <span className={classes.textSmall}>
                                    {ticket.player_info.payments_bit
                                        .last_payment &&
                                        moment(
                                            ticket.player_info.payments_bit
                                                .last_payment
                                        ).format('DD.MM.YYYY HH:mm')}
                                </span>
                            </div>
                        ),
                    platform: ticket.last_player_message.client_info.platform,
                    player: ticket.player_info && (
                        <div className={classes.player}>
                            <span>
                                <b>{ticket.player_info.name}</b> (
                                {ticket.player_info.redeem},{' '}
                                <span className={classes.textSmall}>
                                    country:{ticket.player_info.country}
                                </span>
                                )
                            </span>
                            <span>
                                Platform:&nbsp;
                                <b>
                                    {
                                        ticket.last_player_message.client_info
                                            .platform
                                    }
                                </b>{' '}
                                Version:&nbsp;
                                <b>
                                    {
                                        ticket.last_player_message.client_info
                                            .version
                                    }
                                </b>
                            </span>
                            <span>
                                Http_user_agent:&nbsp;
                                <b>
                                    {
                                        ticket.last_player_message.client_info
                                            .http_user_agent
                                    }
                                </b>
                            </span>
                            <span className={classes.textSmall}>
                                {ticket.profile_id}
                            </span>
                        </div>
                    ),
                })
            })
        }
        setDataRows(rows)
    }, [tickets])

    const onChangeIdHandler = (event) => {
        if (event.target.value >= 0) {
            setFilters({ ...filters, ticket_id: event.target.value })
        }
    }

    const isSite = () => {
        return window.location.hash.includes('site-tickets')
    }
    const onChangeFieldHandler = (field, value) => {
        setFilters({ ...filters, [field]: value })
        if (field === 'state' || field === 'paying') {
            const newFilters = { ...filters, [field]: value }
            let questFilter = getQuestFilters(newFilters)
            loadListTickets(questFilter, filters.sort_by, isSite())
        }
    }

    const onChangeSortHandler = (name) => {
        let sort_by
        if (name === 'Created') {
            if (filters.sort_by === '-created_on') {
                sort_by = 'created_on'
            } else {
                sort_by = '-created_on'
            }
        } else {
            if (filters.sort_by === '-first_unanswered_on') {
                sort_by = 'first_unanswered_on'
            } else {
                sort_by = '-first_unanswered_on'
            }
        }
        setFilters({ ...filters, sort_by })
        applyHandler(sort_by)
    }

    const applyHandler = (sort_by) => {
        if (typeof sort_by === 'string') {
            let questFilter = getQuestFilters(filters)
            loadListTickets(questFilter, sort_by, isSite())
        } else {
            let questFilter = getQuestFilters(filters)
            loadListTickets(questFilter, filters.sort_by, isSite())
        }
    }

    const openTicketHandler = (ticketId, player_id) => {
        loadTicket(ticketId, isSite())
        loadTicketsByPlayer(player_id, isSite())
        setOpenTicket(true)
    }

    return (
        <>
            <InGameTickets
                onChangeId={onChangeIdHandler}
                filters={filters}
                rows={rows}
                onChangeSort={onChangeSortHandler}
                ticketsIsLoading={ticketsIsLoading}
                onChangeField={onChangeFieldHandler}
                onClickApply={applyHandler}
                openTicket={openTicketHandler}
                loadMore={() =>
                    loadMore(
                        cursor,
                        getQuestFilters(filters),
                        filters.sort_by,
                        isSite()
                    )
                }
                cursor={cursor}
                isSite={isSite()}
            />
            <DialogTicket
                open={openTicket}
                onClose={() => setOpenTicket(false)}
            />
        </>
    )
}

const mapStateToProps = (state) => ({
    tickets: getTickets(state),
    ticketsIsLoading: getTicketsIsLoading(state),
    cursor: getNextCursor(state),
})

const mapDispatchToProps = (dispatch) => ({
    loadMore: (cursor, filter, sort, site) =>
        dispatch({
            type: types.GAME_LOAD_MORE_START,
            cursor,
            filter,
            sort,
            site,
        }),
    loadListTickets: (filter, sort, site) =>
        dispatch({
            type: types.GAME_TICKETS_LIST_LOAD_START,
            filter,
            sort,
            site,
        }),
    loadTicket: (id, site) =>
        dispatch({ type: types.GET_TICKET_LOAD_START, id, site }),
    loadTicketsByPlayer: (id, site) =>
        dispatch({ type: types.GET_TICKETS_BY_PLAYER_LOAD_START, id, site }),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(InGameTicketsContainer)
