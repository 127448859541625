import React, { useEffect, useState } from 'react'
import { Button, TextField } from '@material-ui/core'
import moment from 'moment'

const AnswerFiled = ({
    title,
    text,
    active,
    updated_on,
    deleteAnswer,
    id,
    editAnswer,
    answers,
}) => {
    const [isEditing, setIsEditing] = useState(false)
    const [editActive, setEditActive] = useState(false)
    const [editText, setEditText] = useState('')
    const [editTitle, setEditTitle] = useState('')

    useEffect(() => {
        setEditText(text)
    }, [text])

    useEffect(() => {
        setEditTitle(title)
    }, [title])

    useEffect(() => {
        setEditActive(active)
    }, [active])

    const onClickSaveEditHandler = () => {
        if (!isEditing) {
            setIsEditing(true)
        } else {
            setIsEditing(false)
            editAnswer(active, editTitle, editText, id, answers)
        }
    }

    const onClickDeleteCancelHandler = () => {
        !isEditing ? deleteAnswer() : setIsEditing(false)
    }

    const onClickActive = () => {
        editAnswer(!editActive, editTitle, editText, id)
        setEditActive(!editActive)
    }

    return (
        <tr>
            <td>
                {!isEditing ? (
                    title
                ) : (
                    <TextField
                        variant="outlined"
                        onChange={(event) => {
                            setEditTitle(event.target.value)
                        }}
                        value={editTitle}
                        className="w-100"
                        size={'small'}
                    />
                )}
            </td>
            <td>
                {!isEditing ? (
                    text
                ) : (
                    <TextField
                        variant="outlined"
                        onChange={(event) => {
                            setEditText(event.target.value)
                        }}
                        value={editText}
                        className="w-100"
                        size={'small'}
                    />
                )}
            </td>
            <td>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={onClickActive}
                    style={{
                        height: '25px',
                        marginLeft: '10px',
                        padding: '1px',
                        backgroundColor: !editActive ? '#5f050e' : '#1a5f24',
                    }}
                >
                    {editActive + ''}
                </Button>
            </td>
            <td>{moment(updated_on).format('DD.MM.YYYY HH:mm')}</td>
            <td>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={onClickSaveEditHandler}
                    style={{
                        height: '25px',
                        padding: '1px',
                        backgroundColor: '#5c5f39',
                    }}
                >
                    {!isEditing ? 'Edit' : 'Save'}
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={onClickDeleteCancelHandler}
                    style={{
                        height: '25px',
                        marginLeft: '10px',
                        padding: '1px',
                        backgroundColor: '#5f050e',
                    }}
                >
                    {!isEditing ? <i className="fas fa-trash-alt" /> : 'Cancel'}
                </Button>
            </td>
        </tr>
    )
}

export default AnswerFiled
