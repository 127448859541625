import React, { useState } from 'react'
import moment from 'moment'
import {
    Button,
    Card,
    CardContent,
    CircularProgress,
    TextField,
} from '@material-ui/core'
import { connect } from 'react-redux'
import classNames from 'classnames'
import classes from './TicketInfo.module.scss'

import Message from '../Message/Message'
import {
    getTicketById,
    getTicketByIdLoading,
} from '../../store/InGameTickets/reducer'
import * as types from '../../store/InGameTickets/actionTypes'

const TicketInfo = ({
    id,
    created_on,
    state,
    className,
    messages,
    loading,
    actionTicket,
    sendComment,
    sendFromSupport,
}) => {
    const [text, setText] = useState('')
    const isSite = () => {
        return window.location.hash.includes('site-tickets')
    }
    return (
        <div className={classNames(classes.TicketInfo, className)}>
            <Card>
                <CardContent className={classes.TicketInfo__card}>
                    <div className={classes.TicketInfo__about_ticket}>
                        {loading ? (
                            <div className={classes.CircularProgress}>
                                <CircularProgress
                                    variant="indeterminate"
                                    size={40}
                                    thickness={5}
                                />
                            </div>
                        ) : (
                            <>
                                <span>
                                    <b>Ticket ID:</b> {id}
                                </span>
                                <span>
                                    <b>Created:</b>{' '}
                                    {moment(created_on).format(
                                        'DD.MM.YYYY HH:mm'
                                    )}
                                </span>
                                <span>
                                    <b>State:</b> {state}
                                </span>
                                <hr className={classes.TicketInfo__line} />
                                <div className={classes.TicketInfo__buttons}>
                                    {state !== 'opened' && (
                                        <Button
                                            variant="contained"
                                            size="small"
                                            style={{
                                                backgroundColor: '#0a0eb8',
                                                height: '30px',
                                                color: '#ffffff',
                                            }}
                                            onClick={() =>
                                                actionTicket(id, 'open', isSite())
                                            }
                                        >
                                            open
                                        </Button>
                                    )}
                                    {state !== 'closed' && (
                                        <Button
                                            variant="contained"
                                            size="small"
                                            style={{
                                                backgroundColor: '#5cb85c',
                                                height: '30px',
                                            }}
                                            onClick={() =>
                                                actionTicket(id, 'close', isSite())
                                            }
                                        >
                                            close
                                        </Button>
                                    )}
                                    {state !== 'waiting_for_player' && (
                                        <Button
                                            variant="contained"
                                            size="small"
                                            style={{
                                                backgroundColor: '#5bc0de',
                                                height: '30px',
                                            }}
                                            onClick={() =>
                                                actionTicket(
                                                    id,
                                                    'wait_for_player',
                                                    isSite()
                                                )
                                            }
                                        >
                                            <span>Wait&nbsp;player</span>
                                        </Button>
                                    )}
                                    {state !== 'waiting' && (
                                        <Button
                                            variant="contained"
                                            size="small"
                                            style={{
                                                backgroundColor: '#f0ad4e',
                                                height: '30px',
                                            }}
                                            onClick={() =>
                                                actionTicket(id, 'wait', isSite())
                                            }
                                        >
                                            Wait
                                        </Button>
                                    )}
                                </div>
                            </>
                        )}
                    </div>
                    <div className={classes.TicketInfo__form}>
                        <div className={classes.TicketInfo__inputs}>
                            <div className="w-100">
                                <TextField
                                    value={text}
                                    onChange={(event) =>
                                        setText(event.target.value)
                                    }
                                    multiline
                                    rows={4}
                                    variant="outlined"
                                    className="w-100"
                                    placeholder={'Enter answer here...'}
                                />
                            </div>
                            <div
                                style={{
                                    marginLeft: '10px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}
                            >
                                <Button
                                    disabled={!text.length}
                                    variant="contained"
                                    style={{ flexGrow: 1 }}
                                    onClick={sendComment.bind(null, id, text, isSite())}
                                >
                                    Comment
                                </Button>
                                <Button
                                    disabled={!text.length}
                                    variant="contained"
                                    style={{
                                        flexGrow: 1,
                                        backgroundColor: '#37b8f0',
                                    }}
                                    onClick={sendFromSupport.bind(
                                        null,
                                        id,
                                        text,
                                        isSite()
                                    )}
                                >
                                    Send
                                </Button>
                            </div>
                        </div>
                    </div>
                </CardContent>
            </Card>
            {loading ? (
                <div className={classes.CircularProgress}>
                    <CircularProgress
                        variant="indeterminate"
                        size={40}
                        thickness={5}
                    />
                </div>
            ) : (
                <>
                    {messages && (
                        <Card>
                            {messages.map((message) => (
                                <Message key={message.id} {...message} />
                            ))}
                        </Card>
                    )}
                </>
            )}
        </div>
    )
}

const mapStateToProps = (state) => ({
    id: getTicketById(state).id,
    created_on: getTicketById(state).created_on,
    state: getTicketById(state).state,
    messages: getTicketById(state).messages,
    loading: getTicketByIdLoading(state),
})

const mapDispatchToProps = (dispatch) => ({
    actionTicket: (id, actionType, site) =>
        dispatch({ type: types.SET_TICKET_STATE_START, id, actionType, site }),
    sendFromSupport: (id, text, site) =>
        dispatch({ type: types.SEND_FROM_SUPPORT_START, id, text, site }),
    sendComment: (id, text, site) =>
        dispatch({ type: types.SEND_COMMENT_START, id, text, site }),
})

export default connect(mapStateToProps, mapDispatchToProps)(TicketInfo)
