import * as types from './actionTypes'
import { takeLatest, call, all, select, put } from 'redux-saga/effects'
import serverFetch from '../../services/serverFetch'
import { getCurrentProject as getProject } from './reducer'
import { toast } from 'react-toastify'

// Configs fetch func

export function* configsServiceFetch(url, params) {
    const response = yield call(() => serverFetch(`${url}`, params))

    return response
}

function* setConstant({ name, value }) {
    try {
        let project = yield select(getProject)
        if (project) {
            yield configsServiceFetch(`_admin/support/${project}/constants`, {
                method: 'put',
                body: JSON.stringify({ name, value }),
            })

            yield put({
                type: types.MAIN_LOAD_CONSTANTS_LIST_START,
            })
        }
    } catch (e) {
        toast.error(JSON.stringify(e), { autoClose: 3000 })
    }
}

function* loadListConstants() {
    let project = yield select(getProject)
    if (project) {
        const response = yield configsServiceFetch(
            `/_admin/support/${project}/constants`,
            { method: 'GET' }
        )
        if (response) {
            let responseData = yield call(() => {
                return response.json()
            })

            yield put({
                type: types.MAIN_LOAD_CONSTANTS_LIST_END,
                payload: responseData,
            })
        }
    }
}

function* deleteQuickConstants({ name, constants }) {
    console.log(name)
    try {
        let project = yield select(getProject)
        if (project) {
            yield configsServiceFetch(`/_admin/support/${project}/constants`, {
                method: 'DELETE',
                body: JSON.stringify({ name }),
            })
            const resConstants = {}
            Object.keys(constants).forEach((item) => {
                if (item !== name) {
                    resConstants[item] = constants[item]
                }
            })
            yield put({
                type: types.EDIT_CONTENT_END,
                payload: resConstants,
            })
        }
    } catch (e) {
        toast('error')
    }
}

function* watchSetConstant() {
    yield takeLatest(types.MAIN_SET_CONSTANT, setConstant)
}

function* watchDeleteQuickConstants() {
    yield takeLatest(types.DELETE_CONTENT_START, deleteQuickConstants)
}

function* watchLoadListConstants() {
    yield takeLatest(types.MAIN_LOAD_CONSTANTS_LIST_START, loadListConstants)
}

export default function* root() {
    yield all([
        watchSetConstant(),
        watchLoadListConstants(),
        watchDeleteQuickConstants(),
    ])
}
