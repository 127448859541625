import { put, select, takeLatest, all, call } from 'redux-saga/effects'
import { getCurrentProject as getProject } from '../Main/reducer'

import { configsServiceFetch } from '../Main/actions'
import * as types from './actionTypes'
import { toast } from 'react-toastify'

const getUrlForGetList = (project, filter, sort, cursor, site) => {
    return `_admin/support/${project}/${site ? 'site_tickets' : 'tickets'}/?${
        cursor ? `cursor=${cursor}&` : ''
    }filter=${JSON.stringify(filter)}&limit=15&sort_by=${
        sort.indexOf('-') !== -1
            ? encodeURIComponent(sort)
            : encodeURIComponent(`+${sort}`)
    }`
}

// ACTIONS

function* loadListTickets({ filter, sort, site }) {
    let project = yield select(getProject)
    if (project) {
        const response = yield configsServiceFetch(
            getUrlForGetList(project, filter, sort, null, site),
            { method: 'GET' }
        )
        if (response) {
            let responseData = yield call(() => {
                return response.json()
            })

            yield put({
                type: types.GAME_TICKETS_LIST_LOAD_END,
                payload: responseData.tickets,
                next_cursor: responseData.next_cursor,
            })
        }
    }
}

function* loadMore({ cursor, filter, sort, site }) {
    let project = yield select(getProject)
    if (project) {
        const response = yield configsServiceFetch(
            getUrlForGetList(project, filter, sort, cursor, site),
            { method: 'GET' }
        )
        if (response) {
            let responseData = yield call(() => {
                return response.json()
            })

            yield put({
                type: types.GAME_LOAD_MORE_END,
                payload: responseData.tickets,
                next_cursor: responseData.next_cursor,
            })
        }
    }
}

function* loadTicket({ id, site }) {
    let project = yield select(getProject)
    if (project) {
        const response = yield configsServiceFetch(
            `/_admin/support/${project}/${
                site ? 'site_tickets' : 'tickets'
            }/${id}`,
            { method: 'GET' }
        )
        if (response) {
            let responseData = yield call(() => {
                return response.json()
            })
            yield put({
                type: types.GET_TICKET_LOAD_END,
                payload: responseData,
            })
        }
    }
}

function* loadTicketsByPlayer({ id, site }) {
    let project = yield select(getProject)
    if (project) {
        const response = yield configsServiceFetch(
            `_admin/support/${project}/${
                site ? 'site_tickets' : 'tickets'
            }/by_player/${id}`,
            { method: 'GET' }
        )
        if (response) {
            let responseData = yield call(() => {
                return response.json()
            })
            yield put({
                type: types.GET_TICKETS_BY_PLAYER_LOAD_END,
                payload: responseData,
            })
        }
    }
}

function* setStateTicket({ id, actionType, site }) {
    try {
        let project = yield select(getProject)
        if (project) {
            const response = yield configsServiceFetch(
                `_admin/support/${project}/${
                    site ? 'site_tickets' : 'tickets'
                }/${id}/${actionType}`,
                { method: 'POST' }
            )
            if (response) {
                let responseData = yield call(() => {
                    return response.json()
                })
                yield put({
                    type: types.SET_TICKET_STATE_END,
                    payload: responseData,
                })
                yield put({
                    type: types.GET_TICKETS_BY_PLAYER_LOAD_START,
                    id: responseData.profile_id,
                    site
                })
            }
        }
    } catch (e) {
        toast.error(JSON.stringify(e))
    }
}

function* sendFromSupport({ id, text, site }) {
    try {
        let project = yield select(getProject)
        if (project) {
            const response = yield configsServiceFetch(
                `_admin/support/${project}/${
                    site ? 'site_tickets' : 'tickets'
                }/${id}/send_from_support`,
                { method: 'POST', body: JSON.stringify({ text }) }
            )
            if (response) {
                let responseData = yield call(() => {
                    return response.json()
                })
                yield put({
                    type: types.SEND_FROM_SUPPORT_END,
                    payload: responseData,
                })
            }
        }
    } catch (e) {
        toast.error(JSON.stringify(e))
    }
}

function* sendComment({ id, text, site }) {
    try {
        let project = yield select(getProject)
        if (project) {
            const response = yield configsServiceFetch(
                `_admin/support/${project}/${
                    site ? 'site_tickets' : 'tickets'
                }/${id}/comment`,
                { method: 'POST', body: JSON.stringify({ text }) }
            )

            if (response) {
                let responseData = yield call(() => {
                    return response.json()
                })
                yield put({
                    type: types.SEND_COMMENT_END,
                    payload: responseData,
                })
            }
        }
    } catch (e) {
        toast('error')
    }
}

// WATCHERS

function* watchLoadListTickets() {
    yield takeLatest(types.GAME_TICKETS_LIST_LOAD_START, loadListTickets)
}

function* watchSetStateTicket() {
    yield takeLatest(types.SET_TICKET_STATE_START, setStateTicket)
}

function* watchLoadTicket() {
    yield takeLatest(types.GET_TICKET_LOAD_START, loadTicket)
}

function* watchLoadTicketsByPlayer() {
    yield takeLatest(
        types.GET_TICKETS_BY_PLAYER_LOAD_START,
        loadTicketsByPlayer
    )
}

function* watchSendFromSupport() {
    yield takeLatest(types.SEND_FROM_SUPPORT_START, sendFromSupport)
}

function* watchSendComment() {
    yield takeLatest(types.SEND_COMMENT_START, sendComment)
}

function* watchLoadMore() {
    yield takeLatest(types.GAME_LOAD_MORE_START, loadMore)
}

// ROOT

export default function* root() {
    yield all([
        watchLoadListTickets(),
        watchLoadTicket(),
        watchLoadTicketsByPlayer(),
        watchSetStateTicket(),
        watchSendFromSupport(),
        watchSendComment(),
        watchLoadMore(),
    ])
}
