import React from 'react'
import {
    MDBCard,
    MDBCardHeader,
    MDBRow,
    MDBCol,
    MDBTable,
    MDBTableHead,
    MDBTableBody,
    MDBBtn,
} from 'mdbreact'
import {
    CircularProgress,
    Button as ButtonApply,
    MenuItem,
    OutlinedInput,
    Select,
} from '@material-ui/core'

const states = [
    {
        label: 'All',
        value: 'all',
    },
    {
        label: 'Opened',
        value: 'opened',
    },
    {
        label: 'Closed',
        value: 'closed',
    },
    {
        label: 'Waiting',
        value: 'waiting',
    },
    {
        label: 'Waiting Player',
        value: 'waiting_for_player',
    },
]

const Sort = ({ name, sort_by, sort, onChangeSort }) => {
    return (
        <div
            style={{ display: 'flex', cursor: 'pointer', marginBottom: '33px' }}
            onClick={() => onChangeSort(name)}
        >
            {name}
            {sort_by.indexOf(`${sort}`) === -1 && (
                <i
                    style={{ marginLeft: '10px', marginTop: '2px' }}
                    className="fas fa-sort"
                />
            )}
            {sort_by === `${sort}` && (
                <i
                    style={{ marginLeft: '10px', marginTop: '5px' }}
                    className="fas fa-sort-up"
                />
            )}
            {sort_by === `-${sort}` && (
                <i
                    style={{ marginLeft: '10px', marginBottom: '5px' }}
                    className="fas fa-sort-down"
                />
            )}
        </div>
    )
}

const InGameTickets = ({
    rows,
    ticketsIsLoading,
    filters,
    onChangeId,
    onChangeField,
    onChangeSort,
    onClickApply,
    openTicket,
    loadMore,
    cursor,
    isSite,
}) => {
    return (
        <MDBCard>
            <MDBCardHeader>
                <MDBRow>
                    <MDBCol>
                        {isSite ? (
                            <h2>Site Tickets</h2>
                        ) : (
                            <h2>In-game Tickets</h2>
                        )}
                    </MDBCol>
                </MDBRow>
            </MDBCardHeader>
            <MDBTable>
                <MDBTableHead>
                    <tr>
                        <th style={{ textAlign: 'center', width: '50px' }}>
                            <label>
                                ID <br />
                                <OutlinedInput
                                    className={'In-game-tickets'}
                                    onChange={onChangeId}
                                    value={filters.ticket_id}
                                    style={{
                                        height: '25px',
                                        marginLeft: '-10px',
                                        marginRight: '-15px',
                                        textAlign: '11px',
                                        padding: 0,
                                    }}
                                    type="number"
                                />
                            </label>
                        </th>
                        <th>
                            <Sort
                                name={'Created'}
                                sort_by={filters.sort_by}
                                sort={'created_on'}
                                onChangeSort={onChangeSort}
                            />
                        </th>
                        <th>
                            <Sort
                                name={'Since'}
                                sort_by={filters.sort_by}
                                sort={'first_unanswered_on'}
                                onChangeSort={onChangeSort}
                            />
                        </th>
                        <th style={{ textAlign: 'center' }}>
                            <label>
                                State
                                <br />
                                <Select
                                    classes={{
                                        root: `customSelect_rootSelect`,
                                        select: `customSelect_select`,
                                        icon: `customSelect_icon`,
                                    }}
                                    style={{ marginLeft: '10px' }}
                                    value={filters.state}
                                    variant={'outlined'}
                                    onChange={(event) =>
                                        onChangeField(
                                            'state',
                                            event.target.value
                                        )
                                    }
                                >
                                    {states.map((state) => (
                                        <MenuItem
                                            key={`createRule_${state.value}`}
                                            value={state.value}
                                            classes={{
                                                root: `customSelect_rootItem`,
                                            }}
                                        >
                                            {state.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </label>
                        </th>
                        <th style={{ textAlign: 'center' }}>
                            <label>
                                Last player message
                                <br />
                                <OutlinedInput
                                    className={'In-game-tickets'}
                                    onChange={(event) =>
                                        onChangeField(
                                            'message_text',
                                            event.target.value
                                        )
                                    }
                                    value={filters.message_text}
                                    style={{ height: '25px' }}
                                    type="text"
                                />
                            </label>
                        </th>
                        <th style={{ textAlign: 'center' }}>
                            <label>
                                Contact
                                <br />
                                <OutlinedInput
                                    className={'In-game-tickets'}
                                    onChange={(event) =>
                                        onChangeField(
                                            'message_contact_info',
                                            event.target.value
                                        )
                                    }
                                    value={filters.message_contact_info}
                                    style={{ height: '25px' }}
                                    type="text"
                                />
                            </label>
                        </th>
                        <th style={{ textAlign: 'center' }}>
                            <label>
                                IP
                                <br />
                                <OutlinedInput
                                    className={'In-game-tickets'}
                                    onChange={(event) =>
                                        onChangeField(
                                            'message_ip',
                                            event.target.value
                                        )
                                    }
                                    value={filters.message_ip}
                                    style={{ height: '25px' }}
                                    type="text"
                                />
                            </label>
                        </th>
                        <th style={{ textAlign: 'center' }}>
                            <label>
                                Payments
                                <br />
                                <Select
                                    classes={{
                                        root: `customSelect_rootSelect`,
                                        select: `customSelect_select`,
                                        icon: `customSelect_icon`,
                                    }}
                                    value={filters.paying}
                                    variant={'outlined'}
                                    onChange={(event) =>
                                        onChangeField(
                                            'paying',
                                            event.target.value
                                        )
                                    }
                                >
                                    <MenuItem
                                        key={`createRule_all`}
                                        value={'all'}
                                        classes={{
                                            root: `customSelect_rootItem`,
                                        }}
                                    >
                                        {'All'}
                                    </MenuItem>
                                    <MenuItem
                                        key={`createRule_paying`}
                                        value={'paying'}
                                        classes={{
                                            root: `customSelect_rootItem`,
                                        }}
                                    >
                                        {'Paying'}
                                    </MenuItem>
                                </Select>
                            </label>
                        </th>
                        <th style={{ textAlign: 'center' }}>
                            <label>
                                Platform
                                <br />
                                <OutlinedInput
                                    className={'In-game-tickets'}
                                    onChange={(event) =>
                                        onChangeField(
                                            'message_client_info_platform',
                                            event.target.value
                                        )
                                    }
                                    value={filters.message_client_info_platform}
                                    style={{ height: '25px' }}
                                    type="text"
                                />
                            </label>
                        </th>
                        <th style={{ textAlign: 'center' }}>
                            <div style={{ display: 'flex' }}>
                                <label>
                                    Player
                                    <br />
                                    <OutlinedInput
                                        className={'In-game-tickets'}
                                        placeholder={'Player profile id'}
                                        onChange={(event) =>
                                            onChangeField(
                                                'profile_id',
                                                event.target.value
                                            )
                                        }
                                        value={filters.profile_id}
                                        style={{
                                            marginLeft: '-10px',
                                            marginRight: '5px',
                                            height: '25px',
                                        }}
                                        type="text"
                                    />
                                </label>
                                <ButtonApply
                                    variant="contained"
                                    color="primary"
                                    onClick={onClickApply}
                                    style={{
                                        height: '25px',
                                        marginTop: '20px',
                                        padding: '1px',
                                        backgroundColor: '#00c851',
                                    }}
                                >
                                    Apply
                                </ButtonApply>
                            </div>
                        </th>
                    </tr>
                </MDBTableHead>
                <MDBTableBody>
                    {ticketsIsLoading ? (
                        <tr>
                            <td colSpan={10} className="text-center">
                                <CircularProgress
                                    variant="indeterminate"
                                    size={40}
                                    thickness={5}
                                />
                            </td>
                        </tr>
                    ) : (
                        <>
                            {rows.length ? (
                                <>
                                    {rows.map((row, index) => (
                                        <tr
                                            className={'table-row'}
                                            onClick={() =>
                                                openTicket(
                                                    row.id,
                                                    row.profile_id
                                                )
                                            }
                                            key={index}
                                        >
                                            {Object.keys(row).map((key) => (
                                                <React.Fragment
                                                    key={index + key}
                                                >
                                                    {key !== 'profile_id' && (
                                                        <td>{row[key]}</td>
                                                    )}
                                                </React.Fragment>
                                            ))}
                                        </tr>
                                    ))}
                                </>
                            ) : (
                                <tr>
                                    <td colSpan={10} className="text-center">
                                        No data
                                    </td>
                                </tr>
                            )}
                        </>
                    )}
                    {cursor && (
                        <tr>
                            <td colSpan={10} className="text-center">
                                <MDBBtn rounded onClick={() => loadMore()}>
                                    Load more
                                </MDBBtn>
                            </td>
                        </tr>
                    )}
                </MDBTableBody>
            </MDBTable>
        </MDBCard>
    )
}

export default InGameTickets
