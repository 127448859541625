import React from 'react'
import moment from 'moment'
import classes from './Message.module.scss'

const Message = ({
    contact_info,
    ip,
    client_info,
    sent_on,
    text,
    is_comment,
}) => {
    return (
        <div className={classes.Message}>
            {contact_info ? (
                <div className={classes.Message__info}>
                    <span>
                        <b className={classes.Message__info_title}>Contact:</b>{' '}
                        {contact_info}
                    </span>
                    <span>
                        <b className={classes.Message__info_title}>IP:</b> {ip}
                    </span>
                    <span>
                        <b className={classes.Message__info_title}>Platform:</b>{' '}
                        {client_info.platform}
                    </span>
                    <span>
                        <b className={classes.Message__info_title}>Version:</b>{' '}
                        Хmessage.client_info.version}
                    </span>
                    <span>
                        <b className={classes.Message__info_title}>
                            Http_user_agent:
                        </b>{' '}
                        {client_info.http_user_agent}
                    </span>
                </div>
            ) : (
                <div className={classes.Message__info}>
                    fail_to_identify_user
                </div>
            )}
            <div className={classes.Message__text}>
                <span>
                    {moment(sent_on).format('DD.MM.YYYY HH:mm')}
                    {is_comment && <i className="fas fa-comment-alt" />}
                </span>
                <span>{text}</span>
            </div>
        </div>
    )
}

export default Message
