import React, { useEffect, useState } from 'react'
import {
    MDBCard,
    MDBCardHeader,
    MDBCol,
    MDBRow,
    MDBTable,
    MDBTableBody,
    MDBTableHead,
} from 'mdbreact'
import {
    MAIN_LOAD_CONSTANTS_LIST_START,
    MAIN_SET_CONSTANT,
    DELETE_CONTENT_START,
} from '../../store/Main/actionTypes'
import { connect } from 'react-redux'
import {
    getConstants,
    getCurrentProject as getProject,
} from '../../store/Main/reducer'
import { Button, TextField } from '@material-ui/core'

const Constants = ({
    loadListConstants,
    constants,
    setConstant,
    deleteConstant,
    project,
}) => {
    const [name, setName] = useState('')
    const [value, setValue] = useState('')

    useEffect(() => {
        loadListConstants()
    }, [])

    const onClickAddConstantHandler = () => {
        setConstant(name, value)
        setName('')
        setValue('')
    }

    return (
        <MDBCard>
            <MDBCardHeader>
                <MDBRow>
                    <MDBCol>
                        <div style={{ display: 'flex' }}>
                            <h2>Constants</h2>
                        </div>
                    </MDBCol>
                </MDBRow>
            </MDBCardHeader>
            <MDBTable>
                <MDBTableHead>
                    <tr>
                        <th>Name</th>
                        <th>Value</th>
                    </tr>
                </MDBTableHead>
                {constants && (
                    <MDBTableBody>
                        <tr>
                            <td>
                                <TextField
                                    variant="outlined"
                                    onChange={(event) => {
                                        setName(event.target.value)
                                    }}
                                    value={name}
                                    placeholder={'name'}
                                    className="w-100"
                                    size={'small'}
                                />
                            </td>
                            <td>
                                <TextField
                                    variant="outlined"
                                    onChange={(event) => {
                                        setValue(event.target.value)
                                    }}
                                    value={value}
                                    placeholder={'value'}
                                    className="w-100"
                                    size={'small'}
                                />
                            </td>
                            <td>
                                <Button
                                    disabled={!name.length || !value.length}
                                    variant="contained"
                                    color="primary"
                                    onClick={onClickAddConstantHandler}
                                    style={{
                                        height: '40px',
                                    }}
                                >
                                    Add
                                </Button>
                            </td>
                        </tr>
                        {Object.keys(constants).length ? (
                            <>
                                {Object.keys(constants).map((constantName) => (
                                    <tr key={constantName}>
                                        <td>{constantName}</td>
                                        <td>{constants[constantName]}</td>
                                        <td>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={() => {
                                                    if (
                                                        window.confirm(
                                                            `Are you sure you want to delete ${constantName} from ${project}`
                                                        )
                                                    ) {
                                                        deleteConstant(
                                                            constantName,
                                                            constants
                                                        )
                                                    }
                                                }}
                                                style={{
                                                    height: '25px',
                                                    padding: '1px',
                                                    backgroundColor: '#5f050e',
                                                }}
                                            >
                                                <i className="fas fa-trash-alt" />
                                            </Button>
                                        </td>
                                    </tr>
                                ))}
                            </>
                        ) : (
                            <tr>
                                <td colSpan={3} className="text-center">
                                    No data
                                </td>
                            </tr>
                        )}
                    </MDBTableBody>
                )}
            </MDBTable>
        </MDBCard>
    )
}

const mapDispatchToProps = (dispatch) => ({
    loadListConstants: () => {
        dispatch({ type: MAIN_LOAD_CONSTANTS_LIST_START })
    },
    setConstant: (name, value) => {
        dispatch({ type: MAIN_SET_CONSTANT, name, value })
    },
    deleteConstant: (name, constants) => {
        dispatch({ type: DELETE_CONTENT_START, name, constants })
    },
})
const mapStateToProps = (state) => ({
    constants: getConstants(state),
    project: getProject(state),
})

export default connect(mapStateToProps, mapDispatchToProps)(Constants)
