import Immutable from 'seamless-immutable'
import * as types from './actionTypes'

const initialState = Immutable({
    quick_answers: [],
    answersIsLoading: false,
    newAnswerIsLoading: false,
    deleteId: null,
    deleteIsLoading: false,
    edited_answer: null,
    editIsLoading: false,
})

export default function reduce(state = initialState, action = {}) {
    switch (action.type) {
        case types.LOAD_LIST_QUICK_ANSWERS_START:
            return state.merge({
                answersIsLoading: true,
            })
        case types.LOAD_LIST_QUICK_ANSWERS_END:
            return state.merge({
                quick_answers: action.payload,
                answersIsLoading: false,
            })
        case types.SET_QUICK_ANSWER_START:
            return state.merge({
                answerIsLoading: true,
            })
        case types.SET_QUICK_ANSWER_END:
            return state.merge({
                answerIsLoading: false,
                quick_answers: [action.payload, ...state.quick_answers],
            })
        case types.DELETE_QUICK_ANSWER_START:
            return state.merge({
                deleteIsLoading: true,
            })
        case types.EDIT_QUICK_ANSWER_END:
            return state.merge({
                deleteIsLoading: false,
                quick_answers: action.payload,
            })
        case types.EDIT_QUICK_ANSWER_START:
            return state.merge({
                editIsLoading: true,
            })
        default:
            return state
    }
}

export function getAnswers(state) {
    return state.quickAnswers.quick_answers
}

export function getAnswersIsLoading(state) {
    return state.quickAnswers.answersIsLoading
}

export function getNewAnswerLoading(state) {
    return state.quickAnswers.newAnswerIsLoading
}

export function getDeletedAnswer(state) {
    return state.quickAnswers.deleteId
}

export function getDeletedAnswerLoading(state) {
    return state.quickAnswers.deleteIsLoading
}

export function getEditedAnswer(state) {
    return state.quickAnswers.edited_answer
}

export function getEditedAnswerLoading(state) {
    return state.quickAnswers.editIsLoading
}
