import { toast } from 'react-toastify'
import { store } from '../index'
import { MAIN_EMAIL_LOAD_END } from '../store/Main/actionTypes'

export default (url, params = {}) => {
    let token = localStorage.getItem('accessToken')
    if (params.headers === undefined) {
        params.headers = new Headers()
    }
    params.headers.set('Access-Token', token)
    params.headers.set('Accept', 'application/json')
    params.headers.set('Content-Type', 'application/json')
    params.method = params.method ? params.method : 'POST'
    return new Promise(async (resolve, reject) => {
        let serverTimeout = setTimeout(() => {
            let timeoutResponse = new Response(new Blob(), { status: 408 })
            toast.error('Server timeout')
            reject(timeoutResponse)
        }, 120 * 1000)
        let response = await fetch(url, params)
        clearTimeout(serverTimeout)
        if (response.status === 401) {
            store.dispatch({ type: MAIN_EMAIL_LOAD_END })
            localStorage.setItem('email', '')
            localStorage.setItem('accessToken', '')
        }
        if (response.status === 403) {
            toast.error('You have no rights to access this request', {
                autoClose: 5000,
            })
        }
        if (response.status === 500) {
            toast.error('Server error: 500')
        }
        if (response.status === 502) {
            let parsedResponse = await response.json()
            toast.warn(parsedResponse.error, { autoClose: 5000 })
        }
        if (response.status === 504) {
            toast.error('Gateway timeout')
        }
        resolve(response)
    }).then(
        (response) => {
            return response
        },
        (timeoutResponse) => {
            return timeoutResponse
        }
    )
}
