import Immutable from 'seamless-immutable'
import * as types from './actionTypes'

const initialState = Immutable({
    tickets: [],
    nextCursor: null,
    ticketsIsLoading: false,
    currentTicket: null,
    ticketIsLoading: false,
    ticketsByPlayer: null,
    ticketsByPlayerLoading: false,
    loadMoreLoadings: false,
})

export default function reduce(state = initialState, action = {}) {
    switch (action.type) {
        case types.GAME_TICKETS_LIST_LOAD_START:
            return state.merge({
                ticketsIsLoading: true,
            })
        case types.GAME_TICKETS_LIST_LOAD_END:
            return state.merge({
                tickets: action.payload,
                nextCursor: action.next_cursor,
                ticketsIsLoading: false,
            })
        case types.GET_TICKET_LOAD_START:
            return state.merge({
                ticketIsLoading: true,
            })
        case types.GET_TICKET_LOAD_END:
            return state.merge({
                currentTicket: action.payload,
                ticketIsLoading: false,
            })
        case types.GET_TICKETS_BY_PLAYER_LOAD_START:
            return state.merge({
                ticketsByPlayerLoading: true,
            })
        case types.GET_TICKETS_BY_PLAYER_LOAD_END:
            return state.merge({
                ticketsByPlayer: action.payload,
                ticketsByPlayerLoading: false,
            })
        case types.SET_TICKET_STATE_END:
            return state.merge({
                currentTicket: {
                    ...state.currentTicket,
                    state: action.payload.state,
                    closed_on: action.payload.closed_on,
                },
            })
        case types.SEND_FROM_SUPPORT_END:
            return state.merge({
                currentTicket: {
                    ...state.currentTicket,
                    messages: [action.payload, ...state.currentTicket.messages],
                },
            })
        case types.SEND_COMMENT_END:
            return state.merge({
                currentTicket: {
                    ...state.currentTicket,
                    messages: [action.payload, ...state.currentTicket.messages],
                },
            })
        case types.GAME_LOAD_MORE_START:
            return state.merge({
                loadMoreLoadings: true,
            })
        case types.GAME_LOAD_MORE_END:
            return state.merge({
                tickets: [...state.tickets, ...action.payload],
                nextCursor: action.next_cursor,
                ticketsIsLoading: false,
            })
        default:
            return state
    }
}

export function getTickets(state) {
    return state.inGameTickets.tickets
}

export function getTicketsIsLoading(state) {
    return state.inGameTickets.ticketsIsLoading
}

export function getTicketById(state) {
    return state.inGameTickets.currentTicket
}

export function getTicketByIdLoading(state) {
    return state.inGameTickets.ticketIsLoading
}

export function getTicketsByPlayer(state) {
    return state.inGameTickets.ticketsByPlayer
}

export function getTicketsByPlayerLoading(state) {
    return state.inGameTickets.ticketsByPlayerLoading
}

export function getNextCursor(state) {
    return state.inGameTickets.nextCursor
}
