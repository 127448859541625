import { put, select, takeLatest, all, call } from 'redux-saga/effects'
import { getCurrentProject as getProject } from '../Main/reducer'

import { configsServiceFetch } from '../Main/actions'
import * as types from './actionTypes'
import { toast } from 'react-toastify'

// ACTIONS

function* loadListQuickAnswers() {
    let project = yield select(getProject)
    if (project) {
        const response = yield configsServiceFetch(
            `_admin/support/${project}/quick-answers`,
            { method: 'GET' }
        )
        if (response) {
            let responseData = yield call(() => {
                return response.json()
            })

            yield put({
                type: types.LOAD_LIST_QUICK_ANSWERS_END,
                payload: responseData,
            })
        }
    }
}

function* setQuickAnswers({ title, text }) {
    let project = yield select(getProject)
    if (project) {
        const response = yield configsServiceFetch(
            `_admin/support/${project}/quick-answers`,
            {
                method: 'PUT',
                body: JSON.stringify({ active: true, title, text }),
            }
        )
        if (response) {
            let responseData = yield call(() => {
                return response.json()
            })

            yield put({
                type: types.SET_QUICK_ANSWER_END,
                payload: responseData,
            })
        }
    }
}

function* editQuickAnswers({ active, title, text, id, answers }) {
    let project = yield select(getProject)
    if (project) {
        const response = yield configsServiceFetch(
            `_admin/support/${project}/quick-answers/${id}`,
            { method: 'POST', body: JSON.stringify({ active, title, text }) }
        )
        if (response) {
            if (answers) {
                const resAnswers = []
                answers.forEach((item) => {
                    if (item.id === id) {
                        resAnswers.push({ id, active, title, text })
                    } else resAnswers.push(item)
                })
                yield put({
                    type: types.EDIT_QUICK_ANSWER_END,
                    payload: resAnswers,
                })
            }
        }
    }
}

function* deleteQuickAnswer({ id, answers }) {
    try {
        let project = yield select(getProject)
        if (project) {
            yield configsServiceFetch(
                `/_admin/support/${project}/quick-answers/${id}`,
                { method: 'DELETE' }
            )
            yield put({
                type: types.EDIT_QUICK_ANSWER_END,
                payload: [...answers.filter((item) => item.id !== id)],
            })
        }
    } catch (e) {
        toast('error')
    }
}

// WATCHERS

function* watchLoadListQuickAnswers() {
    yield takeLatest(types.LOAD_LIST_QUICK_ANSWERS_START, loadListQuickAnswers)
}

function* watchSetQuickAnswers() {
    yield takeLatest(types.SET_QUICK_ANSWER_START, setQuickAnswers)
}

function* watchEditQuickAnswers() {
    yield takeLatest(types.EDIT_QUICK_ANSWER_START, editQuickAnswers)
}

function* watchDeleteQuickAnswer() {
    yield takeLatest(types.DELETE_QUICK_ANSWER_START, deleteQuickAnswer)
}

// ROOT

export default function* root() {
    yield all([
        watchLoadListQuickAnswers(),
        watchSetQuickAnswers(),
        watchEditQuickAnswers(),
        watchDeleteQuickAnswer(),
    ])
}
