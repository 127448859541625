import InGameTickets from './containers/InGameTickets/InGameTickets'
import QuickAnswers from './containers/QuickAnswers/QuickAnswers'
import Constants from './containers/Constants/Constants'

const routes = [
    {
        path: '/:projectName/in-game-tickets',
        exact: true,
        name: 'In-game Tickets',
        component: InGameTickets,
    },
    {
        path: '/:projectName/site-tickets',
        exact: true,
        name: 'Site-tickets',
        component: InGameTickets,
    },
    {
        path: '/:projectName/manage-quick-answers',
        exact: true,
        name: 'Manage quick answers',
        component: QuickAnswers,
    },
    {
        path: '/:projectName/constants',
        exact: true,
        name: 'Constants',
        component: Constants,
    },
]

export default routes
